import { Link } from "gatsby"
import React, {useState} from "react"
import styled from "styled-components"
import {device, size} from '../utils/media-query.js'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


const Header = ({ siteTitle, menuLinks }) => (
  <StyledHeader>
    <Container>
      <Row justifyContent="space-between">
        <Logo/>
        {/* <Nav menuLinks={menuLinks} /> */}
      </Row>
    </Container>
    
  </StyledHeader>
)

const Container = styled.div`
  max-width: 960px;
  margin: auto;
  padding: 0 1.0825rem 0;
`
const Row = styled.div`
  display: flex;
	flex-direction: row;
  justify-content: ${props => props.justifyContent};
`

const StyledHeader = styled.header`
	background: black;
`



// const Nav = ({menuLinks}) => {
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <nav style={{overflow: "hidden"}}>
//       <OpenClose isOpen={isOpen} setIsOpen={setIsOpen}/> 
//       <StyledBox
//         onClick={() => {setIsOpen(!isOpen)}}
//       >
//         {menuLinks.map(link => (
//           <StyledNavItem 
//             key={link.name}
//             style={{
//               listStyleType: `none`,
//               padding: `1rem`,
//             }}
//           >
//             <Link to={link.link}>
//               {link.name}
//             </Link>
//           </StyledNavItem>
//         ))}

//         </StyledBox>      
//       <NavLinks isOpen={isOpen}>
//         <OpenClose isOpen={isOpen} setIsOpen={setIsOpen}/>
        
//       </NavLinks>
//     </nav>
//   )
// }


const OpenClose = ({isOpen, setIsOpen}) => (  
<StyledOpenClose style={{color: "#fff"}} onClick={() => setIsOpen(!isOpen)}>
  {isOpen && "Close"} Menu
  </StyledOpenClose>
  )
const StyledOpenClose = styled.button`
  background: #000;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  padding: 20px 10px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
`;

const NavLinks = styled.nav`
    flex-direction: row;
    display: flex;
    @media ${device.laptop} {
      visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
      position: fixed;
      top:0;
      right: 0;
      display: flex;
      flex-direction: column;
      background: red;
      min-width: ${size.mobileS};

    }
`
const NavLink = styled.nav`
		a {
  	  color: #fff;
		} 
`
// const NavItem = posed.li({
//   open: { x: '0%' },
//   closed: { x: '100%' }
// })



// const StyledNavItem = styled(NavItem)`
//   color: white;
//   font-family: "Helvetica";
//   font-size: 1.2rem;
//   list-style-type: none;
//   padding-top: 1rem;
//   text-transform: uppercase;
//   a {
//     color: #fff;
//   }


// `




const Logo = ({siteTitle}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Banner-1.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return(    
    <Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
        maxWidth: '100%'
      }}
    >
      <Img
        fixed={data.file.childImageSharp.fixed}
        alt="Gatsby Docs are awesome"
      />
    </Link>
  )
}

export default Header


// const Box = posed.div({
//   open: { x: '0%', staggerChildren: 100 },
//   closed: { x: '100%' },
// });

// const StyledBox = styled(Box)`
//   background: #1b261d;
//   width: 400px;
//   height: 100vh;
//   position: fixed;
//   right: 0;
//   top: 0;
//   padding: 2rem;
//   max-width: 100%;
// `
